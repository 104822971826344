import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import useAuth from '../../hooks/useAuth';
import { useGlobalState } from '../../contexts/GlobalState';
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useUIDCookie from '../../hooks/useUIDCookie';
import { HEADER, NAVBAR } from '../../config';

import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Confetti from '../../components/fun/Confetti';
import Intercom from '../../components/Intercom';
import { logger } from '../../utils/logger';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ isMailbox, isTimeline }) {
  const { user } = useAuth();

  // eslint-disable-next-line no-unused-vars
  const { collapseClick, isCollapse } = useCollapseDrawer();

  const lastSegment = useLocation().pathname.split('/').pop();

  const { themeLayout } = useSettings();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const [globalState, globalDispatch] = useGlobalState();

  const { uid } = useUIDCookie();

  useEffect(() => {
    if (!uid) {
      logger.failed('Cookie:', uid);
    }
  }, [uid]);

  useEffect(() => {
    if (!globalState.confetti) return;
    setTimeout(() => globalDispatch({ ...globalState, confetti: false }), 5000);
  }, [globalState, globalDispatch]);

  if (!uid || !user) return null;

  if (verticalLayout) {
    return (
      <>
        {globalState.confetti ? <Confetti show={globalState.confetti} /> : null}
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          className={lastSegment}
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
        height: '100%',
      }}
    >
      {globalState.confetti ? <Confetti show={globalState.confetti} /> : null}

      <Intercom />

      <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} />

      <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle
        className={lastSegment}
        collapseClick={collapseClick}
        sx={isMailbox || isTimeline ? { padding: '0 !important' } : undefined}
      >
        <Outlet />
      </MainStyle>
    </Box>
  );
}

DashboardLayout.propTypes = {
  isMailbox: PropTypes.bool,
  isTimeline: PropTypes.bool,
};
