import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GoogleOneTapLogin from 'react-google-one-tap-login';
import { useSnackbar } from 'notistack';
import {
  getAuth,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  TwitterAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signInWithCredential,
  sendPasswordResetEmail,
  signInWithCustomToken,
} from 'firebase/auth';
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';

import { FirebaseAuth, FirestoreInstance } from './Firebase';

import { splitName } from '../utils/auth';
import setUserProfile from './partials/setUserProfile';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  method: 'firebase',
  login: () => Promise.resolve(),
  handleGoogleSignIn: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
};

const AuthContext = createContext(initialState);

// ----------------------------------------------------------------------
AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();

  useEffect(
    () =>
      // eslint-disable-next-line
      onAuthStateChanged(FirebaseAuth, async (user) => {
        if (user) {
          await setUserProfile(user, dispatch, setProfile);
        } else {
          await signOut(FirebaseAuth);
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      }),
    [dispatch]
  );

  const getTokenAgain = async () => {
    const user = await FirebaseAuth.currentUser;
    await user.getIdTokenResult(true);

    await setUserProfile(user, dispatch, setProfile);
  };

  const handleError = (err) => {
    if (err.message.includes('auth/blocking-function-error-response') || err.message.includes('Unauthorized')) {
      enqueueSnackbar('You are not allowed to sign up. Please, request an invitation', { variant: 'error' });
    } else {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const handleSocialSignup = async (user) => {
    const userRef = doc(FirestoreInstance, 'users', user.uid);
    const docSnap = await getDoc(userRef);

    if (!docSnap.exists()) {
      await setDoc(
        userRef,
        {
          uid: user?.uid,
          email: `${user?.email}`,
          displayName: `${user?.displayName}`,
          firstName: splitName(user?.displayName).firstName,
          lastName: splitName(user?.displayName).lastName,
          photoURL: `${user?.photoURL}`,
          isPublic: true,
          onboarding: false,
        },
        { merge: true }
      );
    }

    await new Promise((resolve) => setTimeout(resolve, 1500));

    dispatch({
      type: 'LOGIN',
      payload: { isAuthenticated: true, user },
    });

    return user;
  };

  const handleOneTapLogin = (data) =>
    signInWithCredential(FirebaseAuth, GoogleAuthProvider.credential(data.credential));

  const handleGoogleSignIn = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      const resp = await signInWithPopup(auth, provider);
      const { user } = resp;

      await new Promise((resolve) => setTimeout(resolve, 1500));

      await handleSocialSignup(user);
    } catch (err) {
      handleError(err);

      await new Promise((resolve) => setTimeout(resolve, 1500));
      dispatch({
        type: 'LOGIN',
        payload: { isAuthenticated: false, user: null },
      });
    }
  };

  const loginForApps = async () => {
    try {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      return signInWithRedirect(FirebaseAuth, provider);
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleTwitterSignIn = async () => {
    try {
      const auth = getAuth();
      const provider = new TwitterAuthProvider();
      provider.setCustomParameters({
        prompt: 'select_account',
      });
      const resp = await signInWithPopup(auth, provider);

      const credential = TwitterAuthProvider.credentialFromResult(resp);
      const { token, secret } = credential;
      console.log('YOLO TOKEN SECRET', token, secret);

      const { user } = resp;

      await handleSocialSignup(user);

      await new Promise((resolve) => setTimeout(resolve, 1500));

      dispatch({
        type: 'LOGIN',
        payload: { isAuthenticated: true, user },
      });
    } catch (error) {
      handleError(error);

      const { errorCode, errorMessage, email } = error;
      const credential = TwitterAuthProvider.credentialFromError(error);
      // ...
      console.log(error, errorCode, errorMessage, email, credential);

      await new Promise((resolve) => setTimeout(resolve, 1500));

      dispatch({
        type: 'LOGIN',
        payload: { isAuthenticated: false, user: null },
      });
    }
  };

  const OneTapLogin = (props) =>
    !state.isAuthenticated && process.env.NODE_ENV !== 'development' ? (
      <GoogleOneTapLogin
        onError={(error) => console.log(error)}
        onSuccess={(success) => console.log(success)}
        googleAccountConfigs={{
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleOneTapLogin,
        }}
        disableCancelOnUnmount
        {...props}
      />
    ) : null;

  const login = (email, password) => signInWithEmailAndPassword(FirebaseAuth, email, password);

  const register = (email, password, firstName, lastName, industry) =>
    createUserWithEmailAndPassword(FirebaseAuth, email, password).then(async (res) => {
      const userRef = doc(collection(FirestoreInstance, 'users'), res.user?.uid);

      await setDoc(userRef, {
        uid: res.user?.uid,
        email,
        displayName: `${firstName} ${lastName}`,
        firstName,
        lastName,
        isPublic: false,
        industry,
        onboarding: false,
      });
    });

  const resetPassword = (email) => {
    const auth = getAuth();
    return sendPasswordResetEmail(auth, email);
  };

  const impersonateUser = async (customToken) => {
    const auth = getAuth();
    try {
      await signInWithCustomToken(auth, customToken);
    } catch (error) {
      throw new Error(error);
    }
  };

  const logout = () => {
    signOut(FirebaseAuth);
    navigate('/');
  };

  /* eslint-disable */
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: profile,
        login,
        handleGoogleSignIn,
        handleTwitterSignIn,
        loginForApps,
        register,
        logout,
        OneTapLogin,
        resetPassword,
        impersonateUser,
        getTokenAgain,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
  /* eslint-enable */
}

export { AuthContext, AuthProvider };

const reducer = (state, action) => {
  const { isAuthenticated, user } = action.payload;
  switch (action.type) {
    case 'INITIALISE':
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated,
        user,
      };
    default:
      return state;
  }
};