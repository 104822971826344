import {
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  Timestamp,
  arrayRemove,
  arrayUnion,
  writeBatch,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { DateTime } from 'luxon-business-days';
import omit from 'lodash/omit';
import extractDomain from 'extract-domain';
import isValidDomain from 'is-valid-domain';
import isValidEmail from 'is-valid-email';

import { FirestoreInstance, FirebaseFunctions } from '../../contexts/Firebase';
import { dropNullUndefined, removeEmpty } from '../../utils/transformers';
import { splitName } from '../../utils/auth';
import { ACCOUNTS } from '../../config';

export const addNylasAccountDB = httpsCallable(FirebaseFunctions, 'addNylasAccountLegacy');
export const deleteNylasAccountDB = httpsCallable(FirebaseFunctions, 'deleteNylasAccount');
export const getInvitations = httpsCallable(FirebaseFunctions, 'invitations');
export const createUser = httpsCallable(FirebaseFunctions, 'createUser');
export const deleteUser = httpsCallable(FirebaseFunctions, 'deleteUser');
export const updateUserFunction = httpsCallable(FirebaseFunctions, 'updateUser');
export const customClaimsSetter = httpsCallable(FirebaseFunctions, 'setCustomClaims');
export const customClaimsGetter = httpsCallable(FirebaseFunctions, 'customClaimsGetter');
export const getCustomClaimsForSomeUser = httpsCallable(FirebaseFunctions, 'getCustomClaimsForSomeUser');
export const getFavicons = httpsCallable(FirebaseFunctions, 'getFavicons');
export const verifyEmail = httpsCallable(FirebaseFunctions, 'verifyEmail');
export const getAuthUser = httpsCallable(FirebaseFunctions, 'getUser');
export const getActiveUsers = httpsCallable(FirebaseFunctions, 'getActiveUsers');

export const updateUser = async (uid, raw) => {
  const { email, displayName, phoneNumber } = raw;
  const userData = removeEmpty(dropNullUndefined({ email, displayName, phoneNumber }));
  const rest = removeEmpty(dropNullUndefined(raw));

  const userAuth = await updateUserFunction({ uid, data: userData });

  const userRef = doc(collection(FirestoreInstance, 'users'), uid);
  await setDoc(
    userRef,
    {
      ...rest,
      uid,
    },
    { merge: true }
  );

  return userAuth;
};
// CLIENTS

export const createClient = async ({ user, email, password, displayName, ...data }) => {
  const createdAt = Timestamp.now();

  const userObject = {
    email,
    displayName,
    firstName: splitName(displayName).firstName,
    lastName: splitName(displayName).lastName,
    parentUID: user?.orgId,
    createdBy: user?.uid,
    createdAt,
    ...data,
  };

  try {
    const newUser = await createUser({
      email,
      password,
      displayName,
      isClient: true,
      parentUID: user?.orgId,
      createdBy: user?.uid,
    });
    if (newUser?.data?.error) {
      throw new Error(newUser?.data?.error);
    }

    const userRef = doc(collection(FirestoreInstance, 'users'), newUser.data?.uid);

    await setDoc(userRef, {
      ...omit(userObject, 'password'),
      uid: newUser.data?.uid,
    });

    const uDoc = await getDoc(userRef);

    if (uDoc.exists()) return uDoc.data();
    return null;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const editClient = async ({ uid, ...data }) => {
  const userRef = doc(collection(FirestoreInstance, 'users'), uid);
  await updateDoc(userRef, dropNullUndefined(omit(data, ['password'])));
  const uDoc = await getDoc(userRef);

  if (uDoc.exists()) return uDoc.data();
  return false;
};

export const commitCampaignPriority = async ({ uid, campaigns }) => {
  const userRef = doc(collection(FirestoreInstance, 'users'), uid);

  await updateDoc(userRef, { campaignPriority: campaigns });

  return false;
};

export const deleteClient = async (uid) => {
  try {
    const userRef = doc(collection(FirestoreInstance, 'users'), uid);
    await deleteDoc(userRef);
    await deleteUser(uid);
    return 'Success';
  } catch (err) {
    return err;
  }
};

export const getClient = async (uid) => {
  const clientRef = doc(FirestoreInstance, 'users', uid);
  const resp = await getDoc(clientRef);
  return resp.data();
};

export const getUser = async (uid) => {
  const clientRef = doc(FirestoreInstance, 'users', uid);
  const resp = await getDoc(clientRef);
  return resp.data();
};

export const getProspect = async (campaignId, prospectId) => {
  const prospectRef = doc(collection(FirestoreInstance, 'campaigns'), campaignId, 'people', prospectId);
  const resp = await getDoc(prospectRef);
  return { ...resp.data(), id: prospectId };
};

export const getAllInvitations = async () => {
  const invitations = await getInvitations();
  return invitations.data;
};

export const getAllInvitationsLocal = async () => {
  const querySnapshot = await getDocs(collection(FirestoreInstance, 'invitations'));
  return querySnapshot?.docs?.map((doc) => ({ email: doc.id, ...doc.data(), id: doc.id }));
};

export const addNylasAccount = (data) => addNylasAccountDB(data);

export const setUserInvitation = async ({ user, email }) => {
  const invite = {
    email,
    // eslint-disable-next-line no-nested-ternary
    roles: user.isOwner ? ['ADMIN'] : user.isAdmin ? ['USER'] : ['CLIENT'],
  };

  try {
    const invitation = await setDoc(doc(FirestoreInstance, 'invitations', email), invite, { merge: true });
    return invitation;
  } catch (err) {
    return err;
  }
};

export const getAllClientsForUser = async (uid) => {
  const q = query(collection(FirestoreInstance, 'users'), where('parentUID', '==', uid));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => doc.data());
};

export const addEmailAccountToClient = async ({ clientId, uid, account, status = 'running' }) => {
  const now = Timestamp.now();
  const userRef = doc(collection(FirestoreInstance, 'users'), uid);
  const user = await getDoc(userRef);

  const accountDocRef = doc(collection(FirestoreInstance, `/users/${clientId}/accounts`), account.email_address);
  await setDoc(
    accountDocRef,
    {
      createdAt: now,
      interval: ACCOUNTS.OPTIONS.SEND_INTERVAL,
      sent: 0,
      sentTotal: 0,
      ...account,
      status,
      clientId,
      uid,
      reset: Timestamp.fromMillis(DateTime.local().plus({ days: 1 }).startOf('day').toMillis()),

      limit: ACCOUNTS.OPTIONS.INIT_LIMIT,
      hardLimit: ACCOUNTS.OPTIONS.DEFAULT_HARD_LIMIT,
      ramp: ACCOUNTS.OPTIONS.INIT_RAMP,

      warmup: false,
      warmupLimit: ACCOUNTS.OPTIONS.INIT_WARMUP_LIMIT,
      warmupHardLimit: ACCOUNTS.OPTIONS.INIT_WARMUP_HARD_LIMIT,
      warmupRamp: ACCOUNTS.OPTIONS.INIT_WARMUP_RAMP,
      warmupSent: 0,
      warmupSentTotal: 0,

      advancedMode: true,
      ready: true,
    },
    { merge: true }
  );

  if (user.exists()) {
    return user.data();
  }
};

export const editAccount = async ({ account, field, record = false }) => {
  const accountDocRef = doc(
    collection(FirestoreInstance, `/users/${account.clientId}/accounts`),
    account.email_address
  );
  await setDoc(accountDocRef, field, { merge: true });

  if (record) {
    const now = Timestamp.now();
    const recordDocRef = doc(
      collection(FirestoreInstance, `/users/${account.clientId}/accounts`),
      account.email_address,
      'records',
      now.toMillis().toString()
    );
    await setDoc(recordDocRef, { ...field, createdAt: now }, { merge: true });
  }
};

export const editAccountData = async ({ account, data }) => {
  const accountDocRef = doc(
    collection(FirestoreInstance, `/users/${account.clientId}/accounts`),
    account.email_address
  );
  await setDoc(accountDocRef, { ...data }, { merge: true });
};

export const setAsClientAccountsDefault = async (account, sending) => {
  const batch = writeBatch(FirestoreInstance);
  const coll = collection(FirestoreInstance, `/users/${account.clientId}/accounts`);
  const q = query(coll, where('advancedMode', '==', true));
  const allDocsInThisCollection = await getDocs(q);

  const data = sending
    ? {
        ramp: account?.ramp,
        limit: account?.limit,
        hardLimit: account?.hardLimit,
        interval: account?.interval,
      }
    : {
        warmup: account?.warmup,
        warmupRamp: account?.warmupRamp,
        warmupLimit: account?.warmupLimit,
      };

  allDocsInThisCollection.docs.forEach(async (doc) => {
    batch.update(doc.ref, data);
  });

  await batch.commit();
};

export const deleteNylasAccount = async ({ clientId, uid, account }) => {
  const userRef = doc(collection(FirestoreInstance, 'users'), uid);
  const clientRef = doc(collection(FirestoreInstance, 'users'), clientId);
  const user = await getDoc(userRef);

  const accountDocRef = doc(collection(FirestoreInstance, `/users/${clientId}/accounts`), account.email_address);

  await deleteDoc(accountDocRef);

  // Delete account from accounts object in user
  await updateDoc(userRef, {
    accounts: arrayRemove(account.email_address),
  });

  await updateDoc(clientRef, {
    accounts: arrayRemove(account.email_address),
  });

  if (user.exists()) {
    return user.data();
  }

  return false;
};

export const setCustomClaims = ({ uid, claims }) => customClaimsSetter({ uid, claims });

export const addRemoveFromBlacklist = ({ emails, uid, add, field }) => {
  const user = doc(collection(FirestoreInstance, 'users'), uid);

  return Promise.all(
    emails.map(async (email) => {
      // Get domain from email
      if (add && field === 'blacklist') {
        const domain = extractDomain(email);
        if (!domain || domain === '' || !isValidDomain(domain)) {
          throw new Error('Invalid domain. Please try again.');
        }
        await updateDoc(user, { [field]: arrayUnion(domain) });
      } else if (add && field === 'unsubscribed') {
        if (!email || email === '' || !isValidEmail(email)) {
          throw new Error('Invalid email. Please try again.');
        }
        await updateDoc(user, { [field]: arrayUnion(email) });
      } else {
        await updateDoc(user, { [field]: arrayRemove(email) });
      }
    })
  );
};

export const whitelistDomain = ({ uid, domain }) => {
  const ref = doc(collection(FirestoreInstance, `/users/${uid}/whitelist`), domain);
  return setDoc(ref, { domain }, { merge: true });
};

export const getBlacklist = async (uid) => {
  const user = doc(collection(FirestoreInstance, 'users'), uid);
  const userData = await getDoc(user);
  return userData.data().blacklist;
};

export const hideBanner = async (uid) => {
  const user = doc(collection(FirestoreInstance, 'users'), uid);
  await updateDoc(user, { banner: { hide: true } });
};

export const saveOnboarding = async ({ uid, ...data }) => {
  const user = doc(collection(FirestoreInstance, 'users'), uid);
  await updateDoc(user, { onboarding: data });
};

// Set up team members

export const createTeamMember = async ({ user, email, password, displayName, role, ...data }) => {
  const createdAt = Timestamp.now();

  const userObject = {
    email,
    displayName,
    firstName: splitName(displayName).firstName,
    lastName: splitName(displayName).lastName,
    createdAt,
    ...data,
    ...(user?.company && { company: user.company }),
    ...(user?.companyWebsite && { companyWebsite: user.companyWebsite }),
    ...(user?.industry && { industry: user.industry }),
    orgId: user?.orgId,
    role,
  };

  try {
    const newUser = await createUser({ email, password, displayName, isTeam: true, role, orgId: user?.orgId });
    if (newUser?.data?.error) {
      throw new Error(newUser?.data?.error);
    }

    const userRef = doc(collection(FirestoreInstance, 'users'), newUser.data?.uid);

    await setDoc(userRef, {
      ...omit(userObject, 'password'),
      uid: newUser.data?.uid,
    });

    const uDoc = await getDoc(userRef);

    if (uDoc.exists()) {
      return uDoc.data();
    }

    return null;
  } catch (err) {
    throw new Error(err.message);
  }
};