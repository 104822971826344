import { PATH_DASHBOARD } from '../../../routes/paths';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  mail: getIcon('ic_mail'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  user: getIcon('ic_user'),
  team: getIcon('ic_team'),
  prospects: getIcon('gm_users'),
  sequences: getIcon('gm_sequence'),
  schedules: getIcon('ic_schedule'),
  campaigns: getIcon('gm_campaigns'),
  menuItem: getIcon('ic_menu_item'),
  automations: getIcon('gm_automations'),
  templates: getIcon('ic_molecule'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Admin',
  //   items: [
  //     { title: 'Create user', path: PATH_DASHBOARD.user.newUser, icon: ICONS.user },
  //     { title: 'Users', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
  //   ],
  // },
  {
    // subheader: 'User',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.dashboard, icon: ICONS.user },
      {
        title: 'Mailbox',
        path: PATH_DASHBOARD.general.mailbox,
        icon: ICONS.mail,
        stripeRole: ['agency', 'solo', 'enterprise'],
        role: ['copywriter', 'readOnly', 'admin', 'teamManager', 'inboxManager'],
      },
      {
        title: 'Clients',
        path: PATH_DASHBOARD.clients.list,
        icon: ICONS.user,
        stripeRole: ['agency', 'enterprise'],
        role: ['copywriter', 'readOnly', 'admin', 'teamManager', 'inboxManager'],
      },

      {
        title: 'Campaigns',
        path: PATH_DASHBOARD.campaigns.list,
        icon: ICONS.campaigns,
        stripeRole: ['agency', 'solo', 'enterprise'],
        role: ['copywriter', 'readOnly', 'admin', 'teamManager', 'inboxManager'],
      },

      {
        title: 'Templates',
        icon: ICONS.templates,
        stripeRole: ['agency', 'solo', 'enterprise'],
        path: PATH_DASHBOARD.general.templates,
        role: ['copywriter', 'admin', 'teamManager', 'inboxManager'],
      },
      {
        title: 'Automations',
        icon: ICONS.automations,
        stripeRole: ['agency', 'solo', 'enterprise'],
        path: PATH_DASHBOARD.automations.list,
        role: ['copywriter', 'admin', 'teamManager', 'inboxManager'],
      },
      {
        title: 'Team',
        icon: ICONS.team,
        stripeRole: ['agency', 'enterprise'],
        path: PATH_DASHBOARD.team.root,
        role: ['copywriter', 'readOnly', 'admin', 'teamManager', 'inboxManager'],
      },
    ],
  },
];

export default navConfig;
