// @mui
import { Stack, Button, Typography, Box, Divider } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// hooks
import useAuth from '../../../hooks/useAuth';
// routes
import { PATH_DOCS } from '../../../routes/paths';
// assets
import { DocIllustration } from '../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  const { user } = useAuth();
  // media query for min height 800px
  const matches = useMediaQuery('(min-height:1005px)');

  return (
    <Stack spacing={3} sx={{ px: 5, pb: 5, mt: 4, mb: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <Divider flexItem />
      {matches && <DocIllustration sx={{ width: 1 }} />}

      <Box>
        <Typography gutterBottom variant="subtitle1">
          Hi, {user?.displayName}
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
          <br /> Check out our help guides
        </Typography>
      </Box>

      <Button href={PATH_DOCS} target="_blank" rel="noopener" variant="contained">
        Help guides
      </Button>
    </Stack>
  );
}
