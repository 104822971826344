import { collection, doc, getDoc, setDoc, Timestamp } from 'firebase/firestore';
import { FirestoreInstance } from '../../contexts/Firebase';

export const createActivity = async ({
  action,
  data: { id, status, campaignId, activeContact, conversationByThreadId },
  uid,
}) => {
  try {
    const now = Timestamp.now();
    const campaignRef = doc(FirestoreInstance, 'campaigns', campaignId);
    const campaign = (await getDoc(campaignRef)).data();
    const activity = {
      action,
      prospectId: id,
      campaignId,
      clientId: campaign.client,
      creatorId: uid,
      leadStatus: status,
      prospect: activeContact.info,
      on: now,
      account: conversationByThreadId.account,
    };

    const activityRef = collection(FirestoreInstance, `campaigns/${campaignId}/activities`);
    const activityDoc = doc(activityRef);
    await setDoc(activityDoc, activity);
    return activityDoc.id;
  } catch (error) {
    return error.message;
  }
};
