import { containsStripeRole, containsRole } from '../../utils/auth';

const navChecker = (item, user) => {
  const isNotUndef = item && item.stripeRole !== undefined;
  const containsRoleStripe = containsStripeRole({ role: user?.stripeRole, accessibleRoles: item?.stripeRole });
  const containsRoleNormal =
    !user?.isTeamMember || (user?.isTeamMember && containsRole({ role: user?.role, accessibleRoles: item?.role }));
  const hide = item.hide && !!user[`${item.hide}`];

  const show = isNotUndef && containsRoleStripe && containsRoleNormal && !hide;
  return show;
};

export default navChecker;
