import { Suspense, lazy, useContext } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
// layouts
import { AuthContext } from '../contexts/AuthContext';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { ROLE_ROUTES_BY_ALIAS } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

export const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  const loadingProps = {
    isDashboard: pathname.includes('/dashboard'),
    isCampaign: pathname.includes('/campaign'),
    isMailbox: pathname.includes('/mailbox'),
  };

  return (
    <Suspense
      fallback={
        <LoadingScreen {...loadingProps}>
          {loadingProps && loadingProps.isMailbox && (
            <Typography variant="h4" color="text.secondary">
              Loading Mailbox...
            </Typography>
          )}
        </LoadingScreen>
      }
    >
      <Component {...props} className={pathname} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useContext(AuthContext);
  const { pathname } = useLocation();

  const loadingProps = {
    isDashboard: pathname.includes('/dashboard'),
    isCampaign: pathname.includes('/campaign'),
    isMailbox: pathname.includes('/mailbox'),
    isTimeline: pathname.includes('/isTimeline'),
  };

  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout {...loadingProps} />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={ROLE_ROUTES_BY_ALIAS[user?.role] ?? '/dashboard'} replace />,
          index: true,
        },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'dashboard/back_from_stripe', element: <Dashboard stripe /> },
        {
          path: 'impersonate',
          element: (
            <RoleBasedGuard
              stripeRole={user?.stripeRole}
              accessible={user?.isAdmin || user?.isTeam || (user?.stripeRole === 'enterprise' && !user?.isTeamMember)}
            >
              <Impersonate />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'templates',
          children: [
            { element: <Navigate to="/templates/sequences" replace />, index: true },
            {
              path: '',
              element: (
                <RoleBasedGuard
                  stripeRole={user?.stripeRole}
                  accessible={user?.isAdmin || user?.isCopywriter || user?.isTeamManager || user?.isInboxManager}
                >
                  <Templates />
                </RoleBasedGuard>
              ),
              children: [
                {
                  path: 'sequences',
                  element: <SequencesLanding />,
                },
                {
                  path: 'sequences/new',
                  element: <Sequence />,
                },
                {
                  path: 'sequences/:sequenceId',
                  element: <Sequence />,
                },
                {
                  path: 'schedules',
                  element: <SchedulesLanding />,
                },
                {
                  path: 'schedules/new',
                  element: <Schedule />,
                },
                {
                  path: 'schedules/:scheduleId',
                  element: <Schedule />,
                },
              ],
            },
          ],
        },
        {
          path: 'user',
          children: [
            { path: 'profile', element: <UserProfile type="user" /> },
            { path: 'profile/:tab', element: <UserProfile type="user" /> },
          ],
        },
        {
          path: 'team',
          children: [
            { path: 'new', element: <TeamCreate /> },
            {
              path: '/team',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isAgency && !user?.isSolo}>
                  <TeamsLanding />
                </RoleBasedGuard>
              ),
            },

            { path: 'profile/:email', element: <UserProfile type="team" /> },
            { path: 'profile/:email/:tab', element: <UserProfile type="team" /> },
          ],
        },
        {
          path: 'campaigns',
          children: [
            { element: <Navigate to="/dashboard/campaigns" replace />, index: true },
            {
              path: 'new',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <NewCampaign />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new/:id',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <NewCampaign />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/:tab',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <Campaign />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/:tab/:extra',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <Campaign />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/:tab/:extra/:extra2',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <Campaign />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <Campaign />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/people/:active',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <Campaign />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/people/edit/:active',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <Campaign />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <Campaigns />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'automations',
          children: [
            { element: <Navigate to="/dashboard/automations" replace />, index: true },
            {
              path: 'new',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <NewAutomation />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new/:id',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser}>
                  <NewAutomation />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'list',
              element: (
                <RoleBasedGuard
                  stripeRole={user?.stripeRole}
                  accessible={user?.isAdmin || user?.isCopywriter || user?.isTeamManager || user?.isInboxManager}
                >
                  <Automations />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'invitations',
          children: [
            { element: <Navigate to="/dashboard/invitations" replace />, index: true },
            {
              path: 'list',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isAdmin}>
                  <Invitations />
                </RoleBasedGuard>
              ),
            },
            {
              path: '',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isAdmin}>
                  <Invitations />
                </RoleBasedGuard>
              ),
              children: [
                {
                  path: 'new',
                  element: <NewInvitation />,
                },
              ],
            },
          ],
        },
        {
          path: 'mailbox',
          children: [
            {
              element: (
                <AuthGuard>
                  <Mailbox />
                </AuthGuard>
              ),
              index: true,
            },
            { path: 'new/:id', element: <Mailbox /> },
            { path: ':threadId', element: <Mailbox /> },
          ],
        },
        {
          path: 'clients',
          children: [
            { element: <Navigate to="/dashboard/clients" replace />, index: true },
            { path: 'new', element: <ClientCreate /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard stripeRole={user?.stripeRole} accessible={user?.isUser && !user?.isSolo}>
                  <ClientsLanding />
                </RoleBasedGuard>
              ),
            },
            { path: ':email/edit', element: <ClientCreate /> },
            { path: 'profile/:email', element: <UserProfile type="client" /> },
            { path: 'profile/:email/:tab', element: <UserProfile type="client" /> },
            {
              path: 'auth/callback',
              element: <NylasCallback />,
            },
          ],
        },
        /* 
        {
          path: 'reports',
          children: [
            { element: <Navigate to="/dashboard/reports" replace />, index: true },
            {
              path: 'list',
              element: <ReportList />,
              children: [{ path: ':id/edit', element: <EditReportModal /> }],
            },
            { path: 'new', element: <NewReport /> },
            { path: ':uid/:id/', element: <Report /> },
            { path: ':uid/:id/edit', element: <Report edit /> },
          ],
        },
         */
        { path: 'metrics', element: <Metrics /> },
      ],
    },
    {
      path: 'oauth2',
      element: <LoginRedirect />,
    },
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'logout',
          element: <Logout />,
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },
    {
      path: 'reportList/see',
      children: [{ path: ':uid/:id/', element: <Report isPublic /> }],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        {
          path: 'terms-and-conditions',
          element: <TermsAndConditions />,
        },
        {
          path: 'privacy-policy',
          element: <PrivacyPolicy />,
        },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Logout = Loadable(lazy(() => import('../pages/auth/Logout')));
const LoginRedirect = Loadable(lazy(() => import('../pages/auth/LoginRedirect')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const Impersonate = Loadable(lazy(() => import('../pages/auth/Impersonate')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const Dashboard = Loadable(lazy(() => import('../pages/dashboard/Dashboard')));
const Templates = Loadable(lazy(() => import('../pages/dashboard/templates/Templates')));
const Sequence = Loadable(lazy(() => import('../pages/dashboard/sequences/sequence/Sequence')));
const SequencesLanding = Loadable(lazy(() => import('../pages/dashboard/sequences/SequencesLanding')));
const SchedulesLanding = Loadable(lazy(() => import('../pages/dashboard/schedules/SchedulesLanding')));
const Schedule = Loadable(lazy(() => import('../pages/dashboard/schedules/Schedule')));
const Campaigns = Loadable(lazy(() => import('../pages/dashboard/campaigns/Campaigns')));
const Campaign = Loadable(lazy(() => import('../pages/dashboard/campaigns/Campaign')));
const NewCampaign = Loadable(lazy(() => import('../pages/dashboard/campaigns/NewCampaign')));
const Automations = Loadable(lazy(() => import('../pages/dashboard/automations/Automations')));
const NewAutomation = Loadable(lazy(() => import('../pages/dashboard/automations/NewAutomation')));
const Invitations = Loadable(lazy(() => import('../pages/dashboard/invitations/Invitations')));
const NewInvitation = Loadable(lazy(() => import('../pages/dashboard/invitations/NewInvitation')));
const Mailbox = Loadable(lazy(() => import('../pages/dashboard/Mailbox')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/users/UserProfile')));
const ClientCreate = Loadable(lazy(() => import('../pages/dashboard/clients/ClientCreate')));
const Report = Loadable(lazy(() => import('../pages/dashboard/reporting/Report')));
const Metrics = Loadable(lazy(() => import('../pages/dashboard/Metrics')));
const ClientsLanding = Loadable(lazy(() => import('../pages/dashboard/clients/ClientsLanding')));
const TeamsLanding = Loadable(lazy(() => import('../pages/dashboard/team/TeamLanding')));
const TeamCreate = Loadable(lazy(() => import('../pages/dashboard/team/TeamCreate')));

const NylasCallback = Loadable(lazy(() => import('../sections/@dashboard/user/connect/NylasCallback')));
const TermsAndConditions = Loadable(lazy(() => import('../pages/legal/TermsAndConditions')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/legal/PrivacyPolicy')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
